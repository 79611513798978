var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":"新建版本"}},[_c('div',{staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入' }],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"路径"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'url',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]),expression:"[\n                'url',\n                {\n                  rules: [{ required: true, message: '请输入' }],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'platformType',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]),expression:"[\n                'platformType',\n                {\n                  rules: [{ required: true, message: '请选择' }],\n                },\n              ]"}]},_vm._l(([
                  {
                    name: 'android',
                    value: 'android',
                  },
                  {
                    name: 'ios',
                    value: 'ios',
                  },
                ]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }